pre { line-height: 125%; }
td.linenos .normal { color: #D8DEE9; background-color: #242933; padding-left: 5px; padding-right: 5px; }
span.linenos { color: #D8DEE9; background-color: #242933; padding-left: 5px; padding-right: 5px; }
td.linenos .special { color: #242933; background-color: #D8DEE9; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #242933; background-color: #D8DEE9; padding-left: 5px; padding-right: 5px; }
.highlight .hll { background-color: #3B4252 }
.highlight { background: #2E3440; color: #d8dee9 }
.highlight .c { color: #616e87; font-style: italic } /* Comment */
.highlight .err { color: #bf616a } /* Error */
.highlight .esc { color: #d8dee9 } /* Escape */
.highlight .g { color: #d8dee9 } /* Generic */
.highlight .k { color: #81a1c1; font-weight: bold } /* Keyword */
.highlight .l { color: #d8dee9 } /* Literal */
.highlight .n { color: #d8dee9 } /* Name */
.highlight .o { color: #81a1c1; font-weight: bold } /* Operator */
.highlight .x { color: #d8dee9 } /* Other */
.highlight .p { color: #eceff4 } /* Punctuation */
.highlight .ch { color: #616e87; font-style: italic } /* Comment.Hashbang */
.highlight .cm { color: #616e87; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #5e81ac; font-style: italic } /* Comment.Preproc */
.highlight .cpf { color: #616e87; font-style: italic } /* Comment.PreprocFile */
.highlight .c1 { color: #616e87; font-style: italic } /* Comment.Single */
.highlight .cs { color: #616e87; font-style: italic } /* Comment.Special */
.highlight .gd { color: #bf616a } /* Generic.Deleted */
.highlight .ge { color: #d8dee9; font-style: italic } /* Generic.Emph */
.highlight .ges { color: #d8dee9; font-weight: bold; font-style: italic } /* Generic.EmphStrong */
.highlight .gr { color: #bf616a } /* Generic.Error */
.highlight .gh { color: #88c0d0; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #a3be8c } /* Generic.Inserted */
.highlight .go { color: #d8dee9 } /* Generic.Output */
.highlight .gp { color: #616e88; font-weight: bold } /* Generic.Prompt */
.highlight .gs { color: #d8dee9; font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #88c0d0; font-weight: bold } /* Generic.Subheading */
.highlight .gt { color: #bf616a } /* Generic.Traceback */
.highlight .kc { color: #81a1c1; font-weight: bold } /* Keyword.Constant */
.highlight .kd { color: #81a1c1; font-weight: bold } /* Keyword.Declaration */
.highlight .kn { color: #81a1c1; font-weight: bold } /* Keyword.Namespace */
.highlight .kp { color: #81a1c1 } /* Keyword.Pseudo */
.highlight .kr { color: #81a1c1; font-weight: bold } /* Keyword.Reserved */
.highlight .kt { color: #81a1c1 } /* Keyword.Type */
.highlight .ld { color: #d8dee9 } /* Literal.Date */
.highlight .m { color: #b48ead } /* Literal.Number */
.highlight .s { color: #a3be8c } /* Literal.String */
.highlight .na { color: #8fbcbb } /* Name.Attribute */
.highlight .nb { color: #81a1c1 } /* Name.Builtin */
.highlight .nc { color: #8fbcbb } /* Name.Class */
.highlight .no { color: #8fbcbb } /* Name.Constant */
.highlight .nd { color: #d08770 } /* Name.Decorator */
.highlight .ni { color: #d08770 } /* Name.Entity */
.highlight .ne { color: #bf616a } /* Name.Exception */
.highlight .nf { color: #88c0d0 } /* Name.Function */
.highlight .nl { color: #d8dee9 } /* Name.Label */
.highlight .nn { color: #8fbcbb } /* Name.Namespace */
.highlight .nx { color: #d8dee9 } /* Name.Other */
.highlight .py { color: #d8dee9 } /* Name.Property */
.highlight .nt { color: #81a1c1 } /* Name.Tag */
.highlight .nv { color: #d8dee9 } /* Name.Variable */
.highlight .ow { color: #81a1c1; font-weight: bold } /* Operator.Word */
.highlight .pm { color: #eceff4 } /* Punctuation.Marker */
.highlight .w { color: #d8dee9 } /* Text.Whitespace */
.highlight .mb { color: #b48ead } /* Literal.Number.Bin */
.highlight .mf { color: #b48ead } /* Literal.Number.Float */
.highlight .mh { color: #b48ead } /* Literal.Number.Hex */
.highlight .mi { color: #b48ead } /* Literal.Number.Integer */
.highlight .mo { color: #b48ead } /* Literal.Number.Oct */
.highlight .sa { color: #a3be8c } /* Literal.String.Affix */
.highlight .sb { color: #a3be8c } /* Literal.String.Backtick */
.highlight .sc { color: #a3be8c } /* Literal.String.Char */
.highlight .dl { color: #a3be8c } /* Literal.String.Delimiter */
.highlight .sd { color: #616e87 } /* Literal.String.Doc */
.highlight .s2 { color: #a3be8c } /* Literal.String.Double */
.highlight .se { color: #ebcb8b } /* Literal.String.Escape */
.highlight .sh { color: #a3be8c } /* Literal.String.Heredoc */
.highlight .si { color: #a3be8c } /* Literal.String.Interpol */
.highlight .sx { color: #a3be8c } /* Literal.String.Other */
.highlight .sr { color: #ebcb8b } /* Literal.String.Regex */
.highlight .s1 { color: #a3be8c } /* Literal.String.Single */
.highlight .ss { color: #a3be8c } /* Literal.String.Symbol */
.highlight .bp { color: #81a1c1 } /* Name.Builtin.Pseudo */
.highlight .fm { color: #88c0d0 } /* Name.Function.Magic */
.highlight .vc { color: #d8dee9 } /* Name.Variable.Class */
.highlight .vg { color: #d8dee9 } /* Name.Variable.Global */
.highlight .vi { color: #d8dee9 } /* Name.Variable.Instance */
.highlight .vm { color: #d8dee9 } /* Name.Variable.Magic */
.highlight .il { color: #b48ead } /* Literal.Number.Integer.Long */
